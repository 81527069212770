import {
    Divider,
    Grid,
    makeStyles,
    Theme,
    Typography,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    experimentalStyled,
    TableContainer,
} from '@material-ui/core';
import {
    ComponentProps, useEffect, useState, MouseEvent,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { DetailsDrawer } from 'src/components/common/DetailsDrawer';
import { FormArea } from 'src/components/common/DetailsDrawer/FormArea';
import { CSSGrid } from 'src/components/common/CSSGrid';
import { drawerUpdatedState } from 'src/atoms/drawerUpdatedAtom';
import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { TaxonomyList } from 'src/components/common/TaxonomyDiscovery/SearchPanels/TaxonomyList';
import { findId } from 'src/lib/findId';
import { useMutateDesignTaggingGuidance } from 'src/components/DesignTaggingGuidances/hooks/useMutateDesignTaggingGuidance';
import { useDeleteDesignTaggingGuidance } from 'src/components/DesignTaggingGuidances/hooks/useDeleteDesignTaggingGuidance';
import { ConfirmationDialog } from 'src/components/common/ConfirmationDialog';
import { DesignTaggingGuidanceDetailsForm } from 'src/components/DesignTaggingGuidances/DesignTaggingGuidanceDetailsDrawer/DesignTaggingGuidanceDetailsForm';
import { useQueryDesignTaggingGuidance } from 'src/components/DesignTaggingGuidances/hooks/useQueryDesignTaggingGuidance';

export interface PropTypes extends Omit<ComponentProps<typeof DetailsDrawer>, 'id' | 'onDelete' | 'onSave' | 'middleButton'> {
    id: string;
    ducName: string,
    ducId: string,
    changeSelectedId: (id: string) => void;
    closeDrawer: () => void;
}

const StyledTableContainer = experimentalStyled(TableContainer)(({ theme }) => ({
    marginTop: theme.spacing(4),
}));

const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        marginTop: theme.spacing(6),
    },
    title: {
        display: 'flex',
        paddingRight: theme.spacing(18),
    },
    chipSkeleton: {
        borderRadius: theme.spacing(3),
        height: theme.spacing(12),
        width: theme.spacing(16),
        paddingLeft: theme.spacing(18),
        margin: 0,

    },
    tabPanel: {
        paddingTop: 0,
    },
}));

export const DesignTaggingGuidanceDetailsDrawer = (props: PropTypes): JSX.Element => {
    const {
        changeSelectedId,
        closeDrawer,
        id,
        ducName,
        ducId,
        open,
        onClose,
        ...rest
    } = props;
    const classes = useStyles();
    const accessToken = useRecoilValue(isAuthorizedQuery) as string;
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const mutation = useMutateDesignTaggingGuidance(accessToken, id);
    const deleteMutation = useDeleteDesignTaggingGuidance();
    const modalPrompt = `Are you sure you want to delete Tagging Guidance for "${ducName}"?`;

    const [dtgTaxonomies, setDtgTaxonomies] = useState<Models.ContentStoreApi.V3.DesignTaggingGuidanceTaxonomy[]>([]);

    const [taxonomyIds, setTaxonomyIds] = useState<string[]>([]);

    const dtg = useQueryDesignTaggingGuidance(accessToken, id, {
        suspense: false,
        refetchInterval: false,
    });

    const { data: dtgData } = dtg;

    useEffect(() => {
        if (dtgData) {
            setDtgTaxonomies(dtgData.taxonomies);
        }
        const ids = dtgData?.taxonomies.map((x) => findId(x.taxonomy.href));

        setTaxonomyIds(ids || []);
    }, [dtgData]);

    const setUpdatedState = useSetRecoilState(drawerUpdatedState);

    const handleClose = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (onClose) {
            onClose(event, 'escapeKeyDown');
        }
    };
    const handleDelete = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        await deleteMutation.mutateAsync({ accessToken, id });

        handleClose(event);
    };

    const handleSave = async (): Promise<void> => {
        const updatedDTG: Models.ContentStoreApi.V3.UpdateDesignTaggingGuidance = {
            ducId,
            taxonomyIds,

        };

        await mutation.mutateAsync({ accessToken, id, designTaggingGuidance: updatedDTG });

        setUpdatedState(false);
    };

    const handleTaxonomyClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();
        if (event.target.checked) {
            if (!taxonomyIds.includes(event.target.value)) {
                setTaxonomyIds(taxonomyIds.concat(event.target.value));
            }
        } else {
            setTaxonomyIds(taxonomyIds.filter((t) => t !== event.target.value));
        }
    };

    const handleDeleteButton = async (): Promise<void> => {
        setDeleteModalOpen(true);
    };

    const handleAcceptDelete = (event: MouseEvent<HTMLButtonElement>): void => {
        handleDelete(event);
        setDeleteModalOpen(false);
        handleClose(event);
    };

    const handleRejectDelete = (): void => {
        setDeleteModalOpen(false);
    };

    return (
        <>
            <DetailsDrawer
                open={open}
                onClose={onClose}
                onDelete={handleDeleteButton}
                onSave={handleSave}
                {...rest}
            >
                <FormArea title={(
                    <CSSGrid className={classes.title}>
                        <Grid container>
                            <Grid container item xs={6}>
                                <Typography paragraph margin={0} variant="h6">
                                    Design Tagging Guidance Details
                                </Typography>
                            </Grid>
                        </Grid>
                    </CSSGrid>
                )}
                >
                    <DesignTaggingGuidanceDetailsForm
                        id={id}
                        internalName={ducName}
                    />
                </FormArea>
                <Typography fontWeight="medium" mb={2} variant="body1">Suggested Taxonomies</Typography>
                <Grid
                    spacing={2}
                    {...rest}
                    container
                >
                    {dtgTaxonomies.map((t) => (
                        <Grid item key={t.taxonomy.href}>
                            <Chip label={t.taxonomy.taxonomyName} />
                        </Grid>
                    ))}
                </Grid>
                <Divider className={classes.divider} />
                <StyledTableContainer>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" component="th" sx={{ px: 0 }}>
                                    <Typography fontWeight="medium" mb={2} variant="body1">Taxonomies</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TaxonomyList handleCheck={handleTaxonomyClick} selectedTaxonomyIds={taxonomyIds} />
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </DetailsDrawer>
            <ConfirmationDialog
                open={deleteModalOpen}
                title={modalPrompt}
                onAccept={handleAcceptDelete}
                onCancel={handleRejectDelete}
            />

        </>
    );
};
