import {
    Checkbox,
    FormControlLabel,
    makeStyles, Theme,
    TextField,
    Typography,
    IconButton,
    InputAdornment,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useRecoilValue } from 'recoil';
import { useState, useMemo } from 'react';

import { isAuthorizedQuery } from 'src/selectors/isAuthorizedQuery';
import { queryTaxonomies, QUERY_KEY } from 'src/queries/queryTaxonomies';
import { useInfiniteScrollQuery } from 'src/hooks/useInfiniteScrollQuery';
import { InfiniteSearchResultsGrid } from '../../Search/InfiniteSearchResultsGrid';

type PagedTaxonomyKey = App.Taxonomies.PagedQueryKey;
type Taxonomy = Models.ContentStoreApi.V3.Taxonomy;

export interface TaxonomyListPropTypes{
    selectedTaxonomyIds: string[];
    handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void ;
}

export const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
    grid: {
        height: '100%',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        gridTemplateRows: 'auto 1fr',
        gridAutoFlow: 'row dense',
    },
    loader: {
        marginTop: theme.spacing(10),
    },
    tree: {
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
    },
    root: {
        height: 'fit-content',
    },
    content: {
        padding: theme.spacing(5),
    },
    search: {
        paddingBottom: theme.spacing(6),
    },
    searchField: {
        marginBottom: theme.spacing(2),
        width: '50%',
        minWidth: 200,
    },
    emptyState: {
        textAlign: 'center',
        padding: theme.spacing(4),
        color: theme.palette.text.secondary,
    },
    clearButton: {
        padding: 4,
    },
}));

export const TaxonomyList = (props: TaxonomyListPropTypes): JSX.Element => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState('');
    const {
        selectedTaxonomyIds: selectedTaxonomies,
        handleCheck,
    } = props;
    const accessToken = useRecoilValue(isAuthorizedQuery);

    const { data, fetchNextPage, hasNextPage } = useInfiniteScrollQuery<Taxonomy, Error, PagedTaxonomyKey>(
        [QUERY_KEY, accessToken, null],
        queryTaxonomies,
        {
            enabled: !!accessToken,
            retry: 2,
            refetchInterval: 30000,
        },
    );

    function isTaxonomySelected(id: string): boolean {
        return selectedTaxonomies.includes(id);
    }

    const loadMore = async (): Promise<void> => {
        if (hasNextPage) {
            await fetchNextPage();
        }
    };

    const filteredAndSortedData = useMemo(() => {
        if (!data?.length) return [];

        const searchTermLower = searchTerm.toLowerCase();

        return data
            .filter((item) => item.taxonomyName.toLowerCase().includes(searchTermLower))
            .sort((a, b) => {
                const aStartsWith = a.taxonomyName.toLowerCase().startsWith(searchTermLower);
                const bStartsWith = b.taxonomyName.toLowerCase().startsWith(searchTermLower);

                if (aStartsWith && !bStartsWith) return -1;
                if (!aStartsWith && bStartsWith) return 1;

                return a.taxonomyName.localeCompare(b.taxonomyName);
            });
    }, [data, searchTerm]);

    return (
        <div>
            <TextField
                className={classes.searchField}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear search"
                                className={classes.clearButton}
                                size="small"
                                onClick={(): void => setSearchTerm('')}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                placeholder="Search taxonomies..."
                size="small"
                value={searchTerm}
                variant="outlined"
                onChange={(e): void => setSearchTerm(e.target.value)}
            />

            {!data?.length && (
                <Typography className={classes.emptyState}>
                    No taxonomies available
                </Typography>
            )}
            {data?.length && !filteredAndSortedData.length && (
                <Typography className={classes.emptyState}>
                    No results found for &quot;
                    {searchTerm}
                    &quot;
                </Typography>
            )}
            {!!filteredAndSortedData.length && (
                <InfiniteSearchResultsGrid
                    data={filteredAndSortedData}
                    hasMore={!!hasNextPage}
                    itemKey={(item: Models.ContentStoreApi.V3.Taxonomy): string => `item-${item.id}`}
                    loadMore={loadMore}
                    minWidth={400}
                >
                    {(item: Models.ContentStoreApi.V3.Taxonomy): JSX.Element => (
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={isTaxonomySelected(item.id)}
                                    color="primary"
                                    value={item.id}
                                    onChange={handleCheck}
                                />
                            )}
                            label={item.taxonomyName}
                        />
                    )}
                </InfiniteSearchResultsGrid>
            )}
        </div>
    );
};
